import Link from "next/link";
import { bool, func, oneOf, string } from "prop-types";
import { forwardRef } from "react";

import ArrowLeftThin from "~public/icons/arrow-left-thin.svg";
import ArrowRightThin from "~public/icons/arrow-right-thin.svg";
import Calendar from "~public/icons/calendar.svg";
import Cross from "~public/icons/cross.svg";
import ArrowLeft from "~public/icons/fa/arrow-left.svg";
import ArrowRight from "~public/icons/fa/arrow-right.svg";
import Balloon from "~public/icons/fa/balloon.svg";
import Facebook from "~public/icons/facebook.svg";
import Heart from "~public/icons/heart.svg";
import LinkSvg from "~public/icons/link.svg";
import Min from "~public/icons/min.svg";
import Plus from "~public/icons/plus.svg";
import Search from "~public/icons/search.svg";
import ThreeDots from "~public/icons/three-dots.svg";
import Twitter from "~public/icons/twitter.svg";
import WhatsApp from "~public/icons/whatsapp.svg";
import classnames from "~utils/classnames";
import { toValidUrl } from "~utils/routing";

import classes from "./IconButton.module.scss";

export const icons = {
  arrowLeft: ArrowLeft,
  arrowLeftThin: ArrowLeftThin,
  arrowRight: ArrowRight,
  arrowRightThin: ArrowRightThin,
  balloon: Balloon,
  calendar: Calendar,
  cross: Cross,
  facebook: Facebook,
  heart: Heart,
  link: LinkSvg,
  min: Min,
  plus: Plus,
  search: Search,
  threeDots: ThreeDots,
  twitter: Twitter,
  whatsapp: WhatsApp,
};

const iconProps = {
  arrowLeft: { style: { height: "1rem" } },
  arrowRight: { style: { height: "1rem" } },
  arrowLeftThin: { style: { height: "1rem" } },
  arrowRightThin: { style: { height: "1rem" } },
  link: { style: { height: "18px" } },
  facebook: { style: { height: "1.3rem" } },
  twitter: { style: { height: "1.2rem" } },
  whatsapp: { style: { height: "1.4rem" } },
  threeDots: { style: { width: "1rem" } },
  plus: {
    "aria-hidden": "true",
    focusable: "false",
  },
  min: {
    "aria-hidden": "true",
    focusable: "false",
  },
  balloon: { style: { height: "1.4rem", focusable: "false" } },
};

/**
 * Get the appropriate icon component
 * @param {string} icon - The icon name
 * @returns {React.ReactNode} - The icon component
 */

const getIconComponent = (icon) => {
  const IconComponent = icons[icon];
  const props = iconProps[icon] || {};
  return <IconComponent {...props} />;
};

/**
 * @typedef {Object} IconButtonProps
 * @property {string} [href] - The URL to link to (if the button is a link)
 * @property {'button' | 'submit'} [type] - The type of the button
 * @property {string} [title] - The title of the button
 * @property {string} [icon] - The icon to display
 * @property {string} [ariaLabel] - The aria-label for the button
 * @property {'default' | 'primary' | 'secondary' | 'tertiary'} [color] - The color of the button
 * @property {boolean} [disabled] - Whether the button is disabled
 * @property {string} [className] - Additional class names
 * @property {(event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLAnchorElement>) => void} [onClick] - The click handler
 */

const classNamesColorMap = {
  default: classes.icon_button__default,
  primary: classes.icon_button__primary,
  secondary: classes.icon_button__secondary,
  tertiary: classes.icon_button__tertiary,
};

/**
 * IconButton Component
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<IconButtonProps> & React.RefAttributes<HTMLButtonElement | HTMLAnchorElement>>}
 */

const IconButton = forwardRef(
  (
    {
      href,
      type = "button",
      title = "",
      ariaLabel,
      icon = "calendar",
      color = "default",
      onClick,
      className,
      disabled = false,
    },
    ref,
  ) => {
    if (href) {
      return (
        <Link
          href={toValidUrl(href)}
          onClick={onClick}
          className={classnames(classNamesColorMap[color], className)}
          title={title}
          aria-label={ariaLabel}
        >
          {getIconComponent(icon)}
        </Link>
      );
    }

    return (
      <button
        type={type}
        title={title}
        onClick={onClick}
        disabled={disabled}
        className={classnames(classNamesColorMap[color], className)}
        aria-label={ariaLabel}
        aria-disabled={disabled}
        ref={/** @type {React.Ref<HTMLButtonElement>} */ (ref)}
      >
        {getIconComponent(icon)}
      </button>
    );
  },
);

IconButton.displayName = "IconButton";

IconButton.propTypes = {
  href: string,
  type: oneOf(["button", "submit"]),
  disabled: bool,
  title: string,
  ariaLabel: string,
  onClick: func,
  icon: oneOf(Object.keys(icons)).isRequired,
  color: oneOf(["default", "primary", "secondary", "tertiary"]),
  className: string,
};

export default IconButton;
